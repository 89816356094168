import { DOCUMENT } from "@angular/common";
import { APP_INITIALIZER, DestroyRef, FactoryProvider, Inject, Injectable, OnDestroy, Type } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Meta } from "@angular/platform-browser";
import { themeSwitcher as ixThemeSwitcher } from "@siemens/ix";
import { CookieService } from "ngx-cookie-service";
import { distinctUntilChanged, first, map, Observable, startWith, Subject, timer } from "rxjs";
import { IxTheme } from "../enums/ix-theme.enum";

type System = "system"; // Based on the OS's (or browser) theming settings
type Theme = IxTheme.BrandDark | IxTheme.BrandLight;

/** Provides and extends some extra features and control over IX's Standard Theme Handling */
@Injectable()
export class AppThemeService implements OnDestroy {
  /** The key used to save the user's preference */
  private readonly cookieKey = "ix-siemens-theme";

  // Mozilla's spec states light themes are the default if there's no preference
  private readonly defaultTheme: Theme = IxTheme.BrandLight;

  /** helper to emit changes on the themes used and related settings */
  private readonly themeChangedSubject = new Subject<void>();

  /** All valid (for the application) theme settings options */
  private readonly allThemeConfigSet: ReadonlySet<unknown> = new Set<Theme | System>(["system", IxTheme.BrandDark, IxTheme.BrandLight]);

  /* https://getbootstrap.com/docs/5.3/customize/color-modes/#enable-dark-mode */
  private readonly rootAttributeBsKey = "data-bs-theme";

  /** used to disable transitions while changing themes, definition on styles.scss */
  private readonly rootAttributeNoTransition = "no-transitions";

  /** Observable of the events triggered when user changes the system's color preferences */
  private readonly prefersColorSchemeChange$ = new Observable<MediaQueryListEvent>((subscriber) => {
    // Test against the opposite theme type of the default (test for light if default is dark and vice-versa)
    const mediaQueryList = window?.matchMedia?.("(prefers-color-scheme: dark)");
    if (!mediaQueryList?.addEventListener) {
      subscriber.complete();
      return undefined;
    }
    const fn = (ev: MediaQueryListEvent): void => subscriber.next(ev);
    mediaQueryList.addEventListener("change", fn);
    return function unsubscribe() {
      mediaQueryList.removeEventListener("change", fn);
    };
  });

  constructor(
    protected readonly destroyRef: DestroyRef,
    @Inject(DOCUMENT) protected readonly document: Document,
    protected readonly cookie: CookieService,
    protected readonly meta: Meta
  ) {
    // Keep track of Ix Theme changes done outside this service just in case (but avoid doing so, please)
    const fn = (): void => this.themeChangedSubject.next();
    ixThemeSwitcher.themeChanged.on(fn);
    this.destroyRef.onDestroy(() => ixThemeSwitcher.themeChanged.off(fn));
  }

  public ngOnDestroy(): void {
    this.themeChangedSubject.complete();
    this.document.documentElement.removeAttribute(this.rootAttributeNoTransition);
  }

  /** @returns Tuple of providers for the app's bootstrapping process */
  public static getProviderList(): [Type<AppThemeService>, FactoryProvider] {
    return [
      AppThemeService,
      {
        provide: APP_INITIALIZER,
        useFactory: (theme: AppThemeService) => (): Promise<void> => theme.init(),
        deps: [AppThemeService],
        multi: true,
      },
    ];
  }

  public getSavedSettingTheme(): Theme | System {
    try {
      const theme = this.cookie.get(this.cookieKey);
      if (this.isThemeSetting(theme)) {
        return theme;
      } else {
        return "system";
      }
    } catch {
      return "system";
    }
  }

  public getSavedSettingThemeObservable(): Observable<Theme | System> {
    return this.themeChangedSubject.pipe(
      startWith(null),
      map(() => this.getSavedSettingTheme()),
      distinctUntilChanged()
    );
  }

  public getCurrentTheme(): Theme {
    return <Theme>ixThemeSwitcher.getCurrentTheme();
  }

  public getCurrentThemeObservable(): Observable<Theme> {
    return this.themeChangedSubject.pipe(
      startWith(null),
      map(() => this.getCurrentTheme()),
      distinctUntilChanged()
    );
  }

  public toggleTheme(): void {
    const current = this.getCurrentTheme();
    if (current === IxTheme.BrandLight) {
      this.setTheme(IxTheme.BrandDark);
    } else {
      this.setTheme(IxTheme.BrandLight);
    }
  }

  public setTheme(theme: Theme | System): void {
    const isSystem = theme === "system";

    try {
      if (isSystem) {
        this.cookie.delete(this.cookieKey, "/");
      } else {
        // 400 days is the maximum allowed on Google Chrome
        this.cookie.set(this.cookieKey, theme, { sameSite: "Strict", secure: true, path: "/", expires: 400 });
      }
    } catch {
      console.warn("App Theme Setting couldn't be saved!");
    }

    // disable transitions
    this.document.documentElement.setAttribute(this.rootAttributeNoTransition, "");

    ixThemeSwitcher.setTheme(isSystem ? this.defaultTheme : theme, isSystem);

    this.themeChangedSubject.next();

    // Update other related themes
    this.setMetaThemeColor();
    this.setBsTheme();

    // re-enable transitions
    timer(0)
      .pipe(first(), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.document.documentElement.removeAttribute(this.rootAttributeNoTransition));
  }

  private init(): Promise<void> {
    try {
      this.setTheme(this.getSavedSettingTheme());
    } catch {
      this.setTheme(this.defaultTheme);
    }
    this.prefersColorSchemeChange$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      if (this.getSavedSettingTheme() === "system") {
        this.setTheme("system");
      }
    });
    return Promise.resolve();
  }

  /** Sets the color for the browser's frame for mobile and pwa */
  private setMetaThemeColor(): void {
    this.meta.getTags("name='theme-color'")?.forEach((tag) => tag.remove());
    // Test against the opposite theme type of the default (test for light if default is dark and vice-versa)
    this.meta.updateTag({ name: "theme-color", content: this.getCurrentTheme() === IxTheme.BrandDark ? "#23233c" : "#f3f3f0" });
  }

  /** Sets X-Twitter's Bootstrap theme */
  private setBsTheme(): void {
    const current = this.getCurrentTheme();
    // Test against the opposite theme type of the default (test for light if default is dark and vice-versa)
    if (current === IxTheme.BrandDark) {
      this.document.documentElement.setAttribute(this.rootAttributeBsKey, "dark");
    } else {
      this.document.documentElement.setAttribute(this.rootAttributeBsKey, "light");
    }
  }

  private isThemeSetting(value: unknown): value is Theme | System {
    return this.allThemeConfigSet.has(value);
  }
}
